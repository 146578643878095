import React from 'react';
import { LogoProps } from './types';

function BurgerMenu({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        d="M0.483871 0C0.35554 0 0.232466 0.0526785 0.141723 0.146447C0.0509791 0.240215 0 0.367392 0 0.5C0 0.632608 0.0509791 0.759785 0.141723 0.853553C0.232466 0.947321 0.35554 1 0.483871 1H12.0968C12.2251 1 12.3482 0.947321 12.4389 0.853553C12.5297 0.759785 12.5806 0.632608 12.5806 0.5C12.5806 0.367392 12.5297 0.240215 12.4389 0.146447C12.3482 0.0526785 12.2251 0 12.0968 0H0.483871ZM0 4.5C0 4.36739 0.0509791 4.24021 0.141723 4.14645C0.232466 4.05268 0.35554 4 0.483871 4H12.0968C12.2251 4 12.3482 4.05268 12.4389 4.14645C12.5297 4.24021 12.5806 4.36739 12.5806 4.5C12.5806 4.63261 12.5297 4.75979 12.4389 4.85355C12.3482 4.94732 12.2251 5 12.0968 5H0.483871C0.35554 5 0.232466 4.94732 0.141723 4.85355C0.0509791 4.75979 0 4.63261 0 4.5ZM0 8.5C0 8.36739 0.0509791 8.24021 0.141723 8.14645C0.232466 8.05268 0.35554 8 0.483871 8H12.0968C12.2251 8 12.3482 8.05268 12.4389 8.14645C12.5297 8.24021 12.5806 8.36739 12.5806 8.5C12.5806 8.63261 12.5297 8.75979 12.4389 8.85355C12.3482 8.94732 12.2251 9 12.0968 9H0.483871C0.35554 9 0.232466 8.94732 0.141723 8.85355C0.0509791 8.75979 0 8.63261 0 8.5Z"
      />
    </svg>
  );
}

export default BurgerMenu;
